import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Title, Para } from "../components/basic"
import { Flex, Box } from "rebass"
import Img from "gatsby-image"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { p: Para },
}).Compiler

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, htmlAst, excerpt } = markdownRemark

  return (
    <Layout hideHeader>
      <SEO
        title={`${frontmatter.name} | ANMC`}
        description={excerpt}
        image={frontmatter.cover && frontmatter.cover.childImageSharp.fluid.src}
      />
      <Flex
        flexWrap="wrap"
        alignItems="center"
        flexDirection={["column", "row", "row", "row"]}
        my={[0, 8, 8, 9]}
        style={{ maxWidth: "1450px" }}
      >
        <Box
          width={[1, 1 / 2, 1 / 2, 1 / 2]}
          px={[5, 5, 7, 8]}
          py={[6, 6, 0, 0]}
        >
          <Title style={{ textAlign: "left" }}>{frontmatter.name}</Title>
          <Para>
            <span style={{ fontWeight: "400" }}>Client:</span>{" "}
            {frontmatter.client}
          </Para>
          <Para>
            <span style={{ fontWeight: "400" }}>Date:</span> {frontmatter.date}
          </Para>
          <Para>
            <span style={{ fontWeight: "400" }}>Service:</span>{" "}
            {frontmatter.service}
          </Para>
        </Box>
        <Box order={[-1, 0, 0, 0]} width={[1, 1 / 2, 1 / 2, 1 / 2]}>
          <Img
            style={{ maxHeight: "450px" }}
            fluid={data.whatwedoImage.childImageSharp.fluid}
            imgStyle={{ objectPosition: "bottom center" }}
          />
        </Box>
      </Flex>
      <Section style={{ paddingTop: 0 }}>{renderAst(htmlAst)}</Section>
    </Layout>
  )
}

export const query = graphql`
  query casestudyDetailsQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerpt
      frontmatter {
        cover {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        name
        date(formatString: "DD MMMM YYYY")
        client
        service
      }
    }
    whatwedoImage: file(relativePath: { eq: "Whatwedo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
